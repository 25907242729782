import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ data, location }) => (
  <Layout location={location}>
    <div className="page-wrapper-inner">
      <Seo
        title="People"
        slug={location.pathname}
        description="At Rosewood Family Advisors LLP, our professionals are here to help you define and meet your financial goals."
      />
      {/* Main */}
      <section className="wrapper style1">
        <div className="container">
          <div id="content">

            {/* Content */}
            <article>
              <header>
                <h2>People</h2>
                <p>Our professionals are here to help you define and meet your financial goals.</p>
              </header>

              {/* Bio 1 */}
              <a id="bios1" name="bios1" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-tina_rosado.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    Tina Rosado
                  </h3>
                  <p><em>Managing Partner</em></p>
                  <p>
                  Tina Rosado is a Managing Partner of Rosewood Family Advisors LLP, a multi-family office provider that serves ultra high net worth individuals and their families. Rosewood Family Advisors provides highly customized accounting, tax, estate planning, philanthropy and risk services tailored to our clients' complex individual needs.
                  </p>
                  <p>
                  Tina Rosado has over two decades of experience in the financial services industry. Over the course of her career, Tina has served venture capitalists, top executives, entrepreneurs, business leaders and their families with multi-generational wealth management, including tax and management of financial activities.
                  </p>
                  <p>
                  Prior to joining Rosewood Family Advisors, Tina worked for nearly 20 years at CTC myCFO, LLC, a large national multi-family office organization. She began her career at Ernst & Young, LLP. Tina received a B.A. in Economics from the University of California, San Diego and completed a Certificate Program in Accounting and Taxation. Tina is a California Certified Public Accountant and a member of the American Institute of Certified Public Accountants.
                  </p>
                </div>
              </section>

              {/* Bio 2 */}
              <a id="bios2" name="bios2" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-harvey_armstrong.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    Harvey L. Armstrong
                  </h3>
                  <p><em>Partner</em></p>
                  <p>
                  Harvey L. Armstrong is a Partner of Rosewood Family Advisors LLP.  Harvey has many years of experience in the financial services sector. This experience includes comprehensive tax advice and a full range of advisory services for businesses, individuals and foundations.
                  </p>
                  <p>
                  Prior to co-founding Rosewood Family Advisors LLP, Harvey co-founded myCFO Inc, an early leading provider of multiple family office services to the ultra-high net worth market.
                  </p>
                  <p>
                  After Bank of Montreal acquired the family office service business of myCFO, Harvey led the business for almost 20 years as the Managing Director of the Palo Alto office.
                  </p>
                  <p>
                  Prior to myCFO, Harvey was a partner in a Big 5 accounting firm. There he held various leadership roles as a Tax Partner, Office Managing Partner and PFP lead partner.
                  </p>
                  <p>
                  Harvey earned bachelor’s and master’s degrees in accounting from San Jose State University, is a California CPA and member of the AICPA.
                  </p>
                </div>
              </section>

              {/* Bio 3 */}
              <a id="bios3" name="bios3" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-david_kenyon.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    David R. Kenyon
                  </h3>
                  <p><em>Partner</em></p>
                  <p>
                  Dave Kenyon is a Partner of Rosewood Family Advisors LLP. He has extensive tax and consulting experience in helping the ultra-high net worth clients and their closely held entities/businesses meet their financial goals and objectives.
                  </p>
                  <p>
                  Prior to joining Rosewood, Dave was a Managing Director for a multi-family office of MyCFO, an affiliate of Bank of Montreal, for 18 years. In addition, he was also a Tax Director in the Silicon Valley Personal Financial Planning Practice at a Big 4 Accounting Firm before he joined MyCFO.
                  </p>
                  <p>
                  Dave holds a bachelor’s degree in accounting from San Jose State University. He is a Certified Public Accountant licensed to practice in the State of California and is a member of the American Institute of Certified Public Accountants and the California Society of Certified Public Accountants.
                  </p>

                </div>
              </section>

              {/* Bio 4 */}
              <a id="bios4" name="bios4" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-kerry_bratton.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    Kerry Bratton
                  </h3>
                  <p><em>Partner</em></p>
                  <p>
                  Kerry Bratton is a Certified Public Accountant and Partner at Rosewood Family Advisors LLP with over 25 years of experience in the fields of tax and accounting. Kerry earned her Bachelors of Science in Business Administration with a concentration in accounting from California Polytechnic State University, San Luis Obispo, CA. After college she spent seven years at a Big 4 accounting firm, starting in the audit department and transitioning to the tax practice providing tax consulting and compliance services to Silicon Valley companies in the information, communications and entertainment industries.
                  </p>
                  <p>
                  Prior to joining Rosewood Family Advisors LLP she was a Director at CTC myCFO, LLC where she provided a wide range of tax consulting and compliance services to Bay Area businesses and high net wealth individuals. She has significant experience providing tax controversy services for clients relating the their examination by the Internal Revenue Service and the Franchise tax board from exam inception through the IRS Appeals process and the development of Tax Court litigation strategies.
                  </p>
                  <p>
                  Kerry has been a Certified Public Account registered in California since 1993, and she is a member of the California Society of CPAs.
                  </p>

                </div>
              </section>

              {/* Bio 5 */}
              <a id="bios5" name="bios5" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-cayce_craven.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    Cayce Craven
                  </h3>
                  <p><em>Partner</em></p>
                  <p>
                  Cayce Craven is a Partner of Rosewood Family Advisors LLP. Cayce brings over 20 years of experience with national CPA firms and multi-family offices, focusing on the tax needs of high net worth individuals, related family groups and closely held businesses. Prior to joining Rosewood, Cayce provided tax planning and accounting services to high net worth clientele.
                  </p>
                  <p>
                  Cayce has a diverse client base including corporate executives, closely held business owners, hedge fund principals, real estate developers and private equity fund managers. In addition, Cayce serves several high net worth family engagements with multiple interrelated partnerships, trusts and individuals. She has developed deep technical expertise on individual, fiduciary, gift, estate, partnership, S Corporation and private foundation taxation. Cayce also provides broad based financial planning and financial education to her clients.
                  </p>
                  <p>
                  Cayce earned a Bachelor of Arts in Business degree with an emphasis in accounting from the University of Notre Dame of Maryland. She is a CPA and holds the AICPA Personal Financial Specialist and Chartered Global Management Accountant designations.
                  </p>

                </div>
              </section>


              {/* Bio 6 */}
              <a id="bios6" name="bios6" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-jim_cody.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    Jim Cody
                  </h3>
                  <p><em>Director of Estate and Gift Planning</em></p>
                  <p>
                  Jim Cody is responsible for overseeing Estate and Gift planning services for Rosewood Family Advisors LLP.
                  </p>
                  <p>
                  Jim is an attorney with over 35 years of professional experience specializing in philanthropy, estate and trust planning.  He counsels clients on sophisticated tax saving, wealth preservation and wealth transfer techniques.  Prior to joining Rosewood Family Advisors, Jim worked for over 18 years with a large national multi-family office organization where he oversaw estate and gift planning nationally.  Prior to that experience, Jim practiced law for over 17 years as a partner in a San Francisco Bay Area law firm where he led its Trusts and Estates practice group and was a member of the firm’s executive committee.
                  </p>
                  <p>
                  Jim earned a bachelor’s degree in economics from Yale University, received his MBA from the Graduate School of Business at the University of California, Berkeley (now the Haas School of Business) and holds a juris doctor degree (JD) from the University of California, Hastings College of the Law.  He is a member of the State Bar of California and is certified as a specialist in Estate Planning, Trust and Probate Law by the California Board of Legal Specialization of the State Bar of California.
                  </p>
                  <p>
                  Jim is also a Fellow of the American College of Trust and Estate Counsel (ACTEC), a nonprofit association of more than 2500 estate planning lawyers and law professors who are elected to membership as Fellows by their peers based on based on their outstanding reputation, exceptional skill, and substantial contributions to the field by lecturing, writing, teaching and participating in bar activities.  The aim of ACTEC is to improve and reform probate, trust and tax laws, procedures, and professional responsibility.
                  </p>
                  <p>
                  Jim serves on the Board of Trustees of Mills-Peninsula Hospital Foundation (currently serving as Chairman of the Board) and has previously served as Treasurer, Finance Committee Chair and Chairman of the Philanthropy Advisors Council.
                  </p>

                </div>
              </section>

              {/* Bio 7 */}
              <a id="bios7" name="bios7" aria-hidden="true"></a>
              <section
                className="wrapper row"
                data-aos="fade-in"
                data-aos-once="false"
                data-aos-duration="600"
                data-aos-offset="0"
              >
                <div className="col-4 col-12-mobile">
                  <figure className="image" style={{ textAlign: 'center' }}>
                    <StaticImage
                      src="../../assets/images/bios-susanna_poon.jpg"
                      style={{ maxWidth: '224px', margin: '1rem auto' }}
                      alt=""
                      width={224}
                    />
                  </figure>
                </div>
                <div className="col-8 col-12-mobile">
                  <h3
                    style={{ marginBottom: '0' }}
                  >
                    Susanna Poon
                  </h3>
                  <p><em>Director of Philanthropy Services</em></p>
                  <p>
                  Susanna Poon is the Director of Philanthropy Services of Rosewood Family Advisors LLP.  Susanna has more than 13 years of estate and charitable planning experience in the wealth management and legal services sector.
                  </p>
                  <p>
                  Prior to Rosewood Family Advisors, Susanna worked for 4 years at a large national multi-family office organization.  Before that, she was a practicing estate planning attorney for more than 9 years and worked at Hanson Bridgett LLP in San Francisco.  She is responsible for delivering comprehensive family office solutions to individuals and families of substantial wealth and specializes in philanthropy and estate planning, counseling clients on sophisticated tax saving and wealth preservation strategies. Specifically, Susanna works with clients to help them develop and refine their charitable goals, set up and maintain private family foundations and donor advised fund accounts, negotiate charitable gift agreements with charitable beneficiaries and educate family members on philanthropic issues.
                  </p>
                  <p>
                  Susanna earned a BA in Political Science and a BS in Communication Studies from Boston University, where she graduated <i>magna cum laude</i>. She earned her JD from the UCLA School of Law in Los Angeles, California. Susanna is a member of the State Bar of California and serves on the board of directors of 18 Reasons, a non-profit community cooking school in San Francisco.
                  </p>

                </div>
              </section>

            </article>

          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default InteriorPage;
